/*
 * Dependencies
 */
import styled from 'styled-components';

/*
 * CONFIG
 */
const RADIUS = '.35rem';
const HEADER_HEIGHT = '8rem';
const FOOTER_HEIGHT = '8rem';

export const HORIZONTAL_SPACING_MOBILE = '2rem';
export const HORIZONTAL_SPACING_LARGE = '3.5rem';

/*
 * CONTAINER
 */
export const Container = styled.div`
	position:absolute;
	top:0;
	left:0;
	right:0;
	bottom:0;

	overflow:hidden;
	display:flex;
	flex-direction:column;

	background:#fcfcfc;

	@media (${props => props.theme.media.tablet}) {
		margin:0;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);

		width:84rem;
		height:74rem;
		max-width:80%;
		max-height:80%;

		border-radius:${RADIUS};
	}
`;

/*
 * HEADER
 */
export const Header = styled.div`
	position:relative;
	flex:0 0 auto;

	display:flex;
	align-items:center;
	justify-content:center;
	width:100%;
	height:${HEADER_HEIGHT};
	box-sizing:border-box;
	padding:0 1rem;

	background:#fff;
	border-bottom:.25rem solid #eaeaea;

	@media (${props => props.theme.media.tablet}) {
		padding:0 ${HORIZONTAL_SPACING_LARGE};

		border-top-left-radius:${RADIUS};
		border-top-right-radius:${RADIUS};
	}
`;

export const Title = styled.div`
	min-width:1px;
	width:100%;
	box-sizing:border-box;
	text-align:center;

	@media (${props => props.theme.media.tablet}) {
		width:auto;
	}
`;

export const TitleText = styled.div`
	font-size:1.8rem;
	font-weight:500;
	line-height:1.2em;

	@media (${props => props.theme.media.tablet}) {
		margin:0 0 .2em;

		&:last-child {
			margin-bottom:0;
		}
	}
`;

export const TitleDesc = styled.div`
	font-size:1.55rem;
	font-weight:200;

	display:none;

	@media (${props => props.theme.media.tablet}) {
		display:block;
	}
`;

export const Addon = styled.div`
	flex:0 0 auto;
	width:6rem;
	padding:1.75rem;
	box-sizing:border-box;

	@media (${props => props.theme.media.tablet}) {
		padding:1rem;
		width:5rem;

		display:${({ buffer }) => buffer ? 'none' : null};
	}
`;

// Close inside the dialog header
export const Close = styled.div`
	padding:1rem 1rem 1.5rem 1rem;
	width:6rem;
	height:100%;
	box-sizing:border-box;
	flex:0 0 auto;

	display:flex;
	align-items:center;
	justify-content:center;

	font-size:3rem;
	color:#868686;

	@media (${props => props.theme.media.tablet}) {
		display:none;
	}
`;

/*
 * CONTENT
 */
export const Content = styled.div`
	flex:1 1 auto;

	width:100%;
	height:100%; /* Fix required for Safari iOS and Sentry#BUSINESS-CENTRALAPP-COM-EY */
	padding:0 ${HORIZONTAL_SPACING_MOBILE};
	box-sizing:border-box;
	overflow-y:scroll;
	pointer-events:auto;
	-webkit-overflow-scrolling:touch;

	background:#fcfcfc;

	@media (${props => props.theme.media.tablet}) {
		padding:0 ${HORIZONTAL_SPACING_LARGE};

		border-top-left-radius:${({ has_header }) => has_header ? 0 : RADIUS};
		border-top-right-radius:${({ has_header }) => has_header ? 0 : RADIUS};
		border-bottom-left-radius:${({ has_footer }) => has_footer ? 0 : RADIUS};
		border-bottom-right-radius:${({ has_footer }) => has_footer ? 0 : RADIUS};
	}
`;

/*
 * FOOTER
 */
export const Footer = styled.div`
	flex:0 0 auto;

	display:${props => props.multiline ? 'block' : 'flex'};
	align-items:center;
	justify-content:center;
	width:100%;
	min-height:${FOOTER_HEIGHT};
	padding:1.5rem ${HORIZONTAL_SPACING_MOBILE};
	box-sizing:border-box;

	background:#fff;
	border-top:0.25rem solid #eaeaea;

	@media (${props => props.theme.media.tablet}) {
		display:flex;

		height:${FOOTER_HEIGHT};
		padding:0 ${HORIZONTAL_SPACING_LARGE};
		border-bottom-left-radius:${RADIUS};
		border-bottom-right-radius:${RADIUS};
	}
`;

export const FooterSection = styled.div`
	width:${({ width, multiline }) => multiline ? '100%' : (width || 'auto')};
	padding-bottom:${({ multiline }) => multiline ? '1.5rem' : 0};

	text-align:center;

	&:first-child {
		text-align:${({ multiline }) => multiline ? 'center' : 'left'};
	}

	&:last-child {
		text-align:${({ multiline }) => multiline ? 'center' : 'right'};
		padding-bottom:0;
	}

	&:first-child:last-child {
		text-align:center;
	}

	@media (${props => props.theme.media.tablet}) {
		padding:0;
		width:${({ width }) => width || 'auto'};

		&:first-child {
			text-align:left;
		}

		&:last-child {
			text-align:right;
			padding-bottom:0;
		}
	}
`;

/*
 * Close - In the overlay
 */
export const CloseInOverlay = styled.div`
	position:absolute;
	top:0;
	right:0;

	display:none;
	width:5.8rem;
	height:5.8rem;
	padding:1rem;
	box-sizing:border-box;

	cursor:pointer;

	color:#fff;
	font-size:3rem;

	@media (${props => props.theme.media.tablet}) {
		display:block;
	}
`;
