/*
 * Dependencies
 */

// Vendors
import React from 'react';
import PropTypes from 'prop-types';

import { Context } from 'components/dialog/context';
import { Body } from './styles.js';

/*
 * DIALOG - CONTENT
 * ================
 */
class Content extends React.PureComponent {

	static propTypes = {
		children: PropTypes.node,
		noPadding: PropTypes.bool.isRequired
	};

	static defaultProps = {
		noPadding: false
	};

	static contextType = Context;

	componentDidMount () {
		this.context.setContent(
			this.propsToContent(this.props)
		);
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		if (nextProps.children !== this.props.children) {
			this.context.setContent(
				this.propsToContent(nextProps)
			);
		}
	}

	componentWillUnmount () {
		this.context.setContent(null);
	}

	/*
	 *
	 */
	propsToContent (props) {
		return (
			<Body noPadding={props.noPadding}>
				{props.children}
			</Body>
		);
	}

	/*
	 *
	 */
	render () {
		return null;
	}
}

export default Content;
