/*
 * Dependencies
 */
import styled from 'styled-components';
import { HORIZONTAL_SPACING_MOBILE, HORIZONTAL_SPACING_LARGE } from 'components/dialog/styles.js';

/*
 * Body
 */
export const Body = styled.div`
	position:relative;
	min-height:100%;
	box-sizing:border-box;

	border-bottom:${HORIZONTAL_SPACING_MOBILE} solid transparent;
	border-top:${({ noPadding }) => noPadding ? 0 : HORIZONTAL_SPACING_MOBILE} solid transparent;

	@media (${props => props.theme.media.tablet}) {
		border-bottom:${HORIZONTAL_SPACING_LARGE} solid transparent;
		border-top:${({ noPadding }) => noPadding ? 0 : HORIZONTAL_SPACING_LARGE} solid transparent;
	}
`;
