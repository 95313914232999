/*
 *
 */

// Vendors
import React from 'react';
import groupBy from 'lodash.groupby';
import { useIntl, FormattedMessage } from 'react-intl';
import Helmet from 'react-helmet';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

// Components
import Section from 'components/section';
import Hero from 'components/hero';
import Button from 'components/button';
import Alert from 'components/alert';
import Card from 'components/card';
import H2 from 'components/h2';

/*
 *
 */
export default function Region ({ region, places=[] }) {

	const intl = useIntl();
	const lang = intl.locale;

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: `page.region.meta_title.${region.id}` })}</title>
				<meta name="description" content={intl.formatMessage({ id: `page.region.meta_desc.${region.id}` })} />
				<meta property="og:image" content={intl.formatMessage({ id: 'global.meta.og_image.list' })} />
				<meta property="og:url" content={intl.formatMessage({ id: 'global.meta.og_url.list' })} />
			</Helmet>
			<Section>
				<Hero
					title={intl.formatMessage({ id: `page.region.title.region.${region.id}` })}
					subtitle={intl.formatMessage({ id: 'page.region.tagline' })}
					ctaText={intl.formatMessage({ id: 'page.home.section.4.add_restaurant' })}
					ctaLink={intl.formatMessage({ id: 'global.link.add_restaurant' })}
				/>
			</Section>
			<Section>
				<div>
							{places && places.length > 25 && (
						<Alert 
							type="success"
							emoji="📣"
							>
							<p>{intl.formatMessage({ id: 'page.region.alert.more50business' }, { businessCount : places.length } )}</p>
						</Alert>
					)}

					{places && places.length < 25 && (
						<Alert 
							type="warning"
							emoji="🤝"
							>
							<p>
								<FormattedMessage
									id="page.region.alert.partnership"
									values={{
										mailto: (...chunks) => (
											<a target="_blank" rel="noopener noreferrer" href="mailto:hello@aide-aux-restaurateurs.fr">
												{chunks}
											</a>
										)
									}}
								/>
							</p>
						</Alert>
					)}

					{places && places.length < 60 && (
						<Alert 
							type="info"
							emoji="🔍"
							>
							<p>
								<FormattedMessage
									id="page.region.alert.suggest"
									values={{
										mailto: (...chunks) => (
											<a target="_blank" rel="noopener noreferrer" href="mailto:hello@aide-aux-restaurateurs.fr">
												{chunks}
											</a>
										),
										suggest: (...chunks) => (
											<a target="_blank" 
												rel="noopener noreferrer" 
												href={intl.formatMessage({ id: 'global.link.add_restaurant' })}
												onClick={e => {
											        // Lets track that custom click
											        trackCustomEvent({
											          // string - required - The object that was interacted with (e.g.video)
											          category: "Link",
											          // string - required - Type of interaction (e.g. 'play')
											          action: "Add Restaurant",
											          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
											          label: "Region - Alert"
											        })
									      		}}>
												{chunks}
											</a>
										)
									}}
								/>
							</p>
						</Alert>
					)}

				</div>

				{places && places.length > 0 && (
					<React.Fragment>
						{Object.entries(groupBy(places, 'location.address.locality.postal_code')).map(([postal_code, places]) => (
							<div key={postal_code} style={{ marginBottom: '6rem' }}>
								<H2>{postal_code} - {places[0].location.translated_addresses[lang] ? places[0].location.translated_addresses[lang].locality.name : places[0].location.address.locality.name}</H2>

								{places.sort((a, b) => a.name.localeCompare(b.name)).map((place, index) => {

									const addr = place.location ? (place.location.translated_addresses[lang] || place.location.address) : null;

									return (
										<Card
											name={place.name}
											address={addr ? `${addr.street_number} ${addr.street?.name}, ${(addr.sub_locality || addr.locality)?.postal_code} ${(addr.sub_locality || addr.locality)?.name}` : ''}
											logo_src={place.logo ? place.logo.image.src : null}
											categories={place.categories.slice(0, 3).map(cat => cat.translations.fr).join(', ')}
											takeaway={place.coronavirus.status && ['PARTIALOPEN_DELIVERY_AND_TAKEAWAY', 'PARTIALOPEN_TAKEAWAY'].includes(place.coronavirus.status)}
											delivery={place.coronavirus.status && ['PARTIALOPEN_DELIVERY_AND_TAKEAWAY', 'PARTIALOPEN_DELIVERY'].includes(place.coronavirus.status)}
											website={place.website || null}
											phone={(place.primary_phone && place.primary_phone.international) || (place.mobile && place.mobile.international)}
											key={index}
											delivery_details={place.delivery_details}
											takeaway_details={place.takeaway_details}
										/>
									);
								})}
							</div>
						))}

						<p style={{ margin: '0 0 1em', textAlign: 'center' }}>
							<Button 
								href={intl.formatMessage({ id: 'global.link.add_restaurant' })}
								onClick={e => {
							        // Lets track that custom click
							        trackCustomEvent({
							          // string - required - The object that was interacted with (e.g.video)
							          category: "Button",
							          // string - required - Type of interaction (e.g. 'play')
							          action: "Add Restaurant",
							          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
							          label: "Region - End of List"
							        })
					      		}}>
								{intl.formatMessage({ id: 'page.home.section.4.add_restaurant' })}
							</Button>
						</p>
					</React.Fragment>
				)}
			</Section>
		</React.Fragment>
	);
}
