/*
 *
 */
import React from 'react';
import Layout from 'layout/index';
import Region from './index';

/*
 *
 */
export default function RegionLayout (props) {
	return (
		<Layout {...props}>
			<Region
				region={props.pageContext.region}
				places={props.pageContext.places}
			/>
		</Layout>
	);
}
