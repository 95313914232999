/*
 * Dependencies
 */

// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Context } from 'components/dialog/context';

// Styles
import { Footer as FooterContainer, FooterSection } from 'components/dialog/styles.js';

/*
 * DIALOG - FOOTER
 * ===============
 */
class Footer extends React.Component {

	static propTypes = {
		children: PropTypes.node,
		multiline: PropTypes.bool.isRequired
	};

	static defaultProps = {
		multiline: false
	};

	static contextType = Context;

	componentDidMount () {
		this.context.setFooter(
			this.propsToFooter(this.props.children)
		);
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		if (nextProps.children !== this.props.children) {
			this.context.setFooter(
				this.propsToFooter(nextProps.children)
			);
		}
	}

	componentWillUnmount () {
		this.context.setFooter(null);
	}

	/*
	 *
	 */
	propsToFooter (children) {

		const childrenArray = React.Children.toArray(children);
		const someChildrenHaveSetWidth = childrenArray.some(child => child.props && child.props.width);

		return (
			<FooterContainer multiline={!!this.props.multiline}>
				{React.Children.map(children, child => {

					const width = someChildrenHaveSetWidth ? (child.props && child.props.width ? child.props.width : 'auto') : `${100 / childrenArray.length}%`;

					return (
						<FooterSection multiline={!!this.props.multiline} width={width}>
							{child}
						</FooterSection>
					);
				})}
			</FooterContainer>
		);
	}

	/*
	 *
	 */
	render () {
		return null;
	}
}

export default Footer;
