/*
 * Dependencies
 */

// Vendors
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// Styles
import { Root, Content, ContentInner, GlobalStyle } from './styles.js';

/*
 * Test support for passive events
 */
function hasPassiveEvents () {

	let passiveSupported = false;

	try {
		const options = {
			// This function will be called when the browser attempts to access the passive property.
			// eslint-disable-next-line
			get passive () {
				passiveSupported = true;
			}
		};

		window.addEventListener('test', options, options);
		window.removeEventListener('test', options, options);
	}
	catch (err) {
		passiveSupported = false;
	}

	return passiveSupported;
}

const PASSIVE_EVENTS = hasPassiveEvents() ? { passive: false } : undefined;

/*
 * MODAL
 * =====
 */
class Modal extends React.PureComponent {

	static propTypes = {
		children: PropTypes.node.isRequired,
		isOpen: PropTypes.bool.isRequired,
		onRequestClose: PropTypes.func,
		CloseButton: PropTypes.func, // props => node
		transitionContent: PropTypes.bool.isRequired,
		overlayColor: PropTypes.oneOf(['light', 'dark'])
	};

	static defaultProps = {
		isOpen: false,
		transitionContent: true,
		overlayColor: 'dark'
	};

	constructor (props) {
		super(props);

		this.el = document.getElementById('modal');

		this.state = {
			active: false,
			scrollbarWidth: null
		};

		this.rootRef = React.createRef();
		this.contentRef = React.createRef();
		this.handleOverlayClick = this.handleOverlayClick.bind(this);
		this.handleKeyboard = this.handleKeyboard.bind(this);

		this.initialTouch = this.initialTouch.bind(this);
		this.preventTouch = this.preventTouch.bind(this);

		this.scrollable = props.scrollable || null;
		this.initialClientY = null;
	}

	componentDidMount () {
		this.getScrollBarSize();

		document.addEventListener('keyup', this.handleKeyboard, true);

		if (this.props.isOpen) {
			this.openModal();
		}
	}

	UNSAFE_componentWillReceiveProps (nextProps) {

		this.scrollable = nextProps.scrollable || null;

		if (nextProps.isOpen && nextProps.isOpen !== this.props.isOpen && !this.state.active) {
			this.openModal();
			return;
		}

		if (!nextProps.isOpen && nextProps.isOpen !== this.props.isOpen) {
			this.cleanUp();
		}
	}

	componentWillUnmount () {
		this.cleanUp();
	}

	/*
	 * Clean up all events when modal closes and/or component unmounts
	 */
	cleanUp () {
		document.removeEventListener('keyup', this.handleKeyboard, true);

		document.removeEventListener('touchstart', this.initialTouch, PASSIVE_EVENTS);
		document.removeEventListener('touchmove',  this.preventTouch, PASSIVE_EVENTS);
	}

	/*
	 * Modal opens
	 */
	openModal () {

		this.setState({
			active: true
		});

		document.addEventListener('keyup', this.handleKeyboard, true);

		// There is still a weird quirk were a 44px heigth area circumventing browser scrolling events is present:
		// https://medium.com/turo-engineering/ios-mobile-scroll-in-web-react-1d92d910604b
		document.addEventListener('touchstart', this.initialTouch, PASSIVE_EVENTS);
		document.addEventListener('touchmove',  this.preventTouch, PASSIVE_EVENTS);
	}

	/*
	 *
	 */
	initialTouch (event) {
		// Detect single touch
		if (event.targetTouches.length === 1) {
			this.initialClientY = event.targetTouches[0].clientY;
		}
	}

	/*
	 *
	 */
	preventTouch (rawEvent) {

		const e = rawEvent || window.event;

		if (!this.initialClientY) {
			e.preventDefault();
			return false;
		}

		const clientY = e.targetTouches[0].clientY - this.initialClientY;

		// Nothing is scrollable
		if (!this.scrollable) {
			e.preventDefault();
			return false;
		}

		// Scrollable and touchmove'd element is scrollable or inside the scrollable element
		if (
			e.target
			&&
			(
				e.target === this.scrollable
				||
				this.scrollable.contains(e.target)
			)
		) {
			// Scrolled to 0
			if (this.scrollable.scrollTop <= 0 && clientY > 0) {
				e.preventDefault();
				return false;
			}

			// Scrolled to max
			if (this.scrollable.scrollHeight - this.scrollable.scrollTop <= this.scrollable.clientHeight && clientY < 0) {
				e.preventDefault();
				return false;
			}

			return true;
		}

		// Do not prevent if the event has more than one touch
		// This usually means this is a multi touch gesture like pinch to zoom
		if (e.touches.length > 1) {
			return true;
		}

		e.preventDefault();
		return false;
	}

	/*
	 *
	 */
	getScrollBarSize () {

		const node = document.createElement('div');

		node.style.width = '100px';
		node.style.height = '100px';
		node.style.overflow = 'scroll';
		node.style.position = 'absolute';
		node.style.top = '-9999px';

		document.body.appendChild(node);

		const scrollbarWidth = node.offsetWidth - node.clientWidth;

		document.body.removeChild(node);

		this.setState({
			scrollbarWidth: scrollbarWidth
		});
	}

	/*
	 *
	 */
	handleKeyboard (e) {
		const keyCode = e.which || e.keyCode;
		const key = e.key;

		if (keyCode === 27 || key === 'Escape') {
			if (this.props.onRequestClose) {
				this.props.onRequestClose();
			}
		}
	}

	/*
	 *
	 */
	handleOverlayClick (e) {

		const { isOpen, onRequestClose } = this.props;

		if (isOpen && onRequestClose && this.contentRef && this.contentRef.current && !this.contentRef.current.contains(e.target)) {
			onRequestClose();
		}
	}

	/*
	 *
	 */
	modal () {

		const { children, transitionContent, overlayColor, CloseButton } = this.props;
		const { active, scrollbarWidth } = this.state;

		return (
			<Root ref={this.rootRef} onClickCapture={this.handleOverlayClick} active={active} overlay_color={overlayColor}>
				<GlobalStyle
					isOpen={active}
					scrollbarWidth={scrollbarWidth}
				/>
				<Content has_transition={transitionContent}>
					<ContentInner ref={this.contentRef}>
						{active ? children : null}
					</ContentInner>
				</Content>
				{CloseButton ? <CloseButton scrollbarWidth={scrollbarWidth} /> : null}
			</Root>
		);
	}

	/*
	 *
	 */
	render () {
		return this.props.isOpen ? ReactDOM.createPortal(
			this.modal(),
			this.el,
		) : null;
	}
}

export default Modal;
