/*
 * Dependencies
 */

// Vendors
import React from 'react';
import PropTypes from 'prop-types';

export const Context = React.createContext();

/*
 * DIALOG
 * =====
 */
class DialogContext extends React.Component {

	static propTypes = {
		children: PropTypes.node
	};

	constructor (props) {
		super(props);

		this.state = {
			content: null,
			footer: null,
			setContent: node => this.setState({ content: node }),
			setFooter: node => this.setState({ footer: node })
		};
	}

	/*
	 *
	 */
	render () {
		return (
			<Context.Provider value={this.state}>
				{this.props.children}
			</Context.Provider>
		);
	}
}

export default DialogContext;
