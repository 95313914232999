/*
 *
 */
import styled, { css } from 'styled-components';


/*
 * Styles
 */
export const Root = styled.div`
	margin:0 0 1.25rem;

	&:last-child {
		margin-bottom:0;
	}

	background:#fff;
	border:1px solid #eaeaea;
	border-radius:3px;
	box-shadow:0 0 2rem 0 rgba(0,0,0,.05);

	font-size:1.2rem;

	@media (${props => props.theme.media.tablet}) {
		font-size:1.4rem;

		display:flex;
	}
`;

export const Content = styled.div`
	padding:.75rem 1.15rem;
	display:flex;
	align-items:center;
	justify-content:space-between;
	width:100%;
	box-sizing:border-box;
	min-width:1px;

	@media (${props => props.theme.media.tablet}) {
		padding:1.25rem 1.5rem;
	}
`;

export const Logo = styled.div`
	flex:0 0 auto;
	border:1px solid #eaeaea;
	border-radius:4px;
	width:4.75rem;
	height:4.75rem;
	@media (${props => props.theme.media.tablet}) {
		width:7.5rem;
		height:7.5rem;
	}
`;

export const LogoImg = styled.div.attrs(({ src }) => ({
	style: {
		backgroundImage: `url(${src})`
	}
}))`
	width:100%;
	height:100%;
	background-size:cover;
	border-radius:3px;
`;

export const Identity = styled.div`
	flex:1 1 auto;
	padding-top:.5rem;
	padding-bottom:.5rem;
	padding-left:1rem;
	padding-right:1rem;
	min-width:1px;
	box-sizing:border-box;
	@media (${props => props.theme.media.tablet}) {
		padding-left:1.5rem;
		padding-right:1.5rem;
	}
`;

export const Features = styled.div`
	flex:0 0 auto;
	display:flex;
	align-items:center;
	flex-direction:column;
`;

export const Name = styled.div`
	margin:0 0 .35em;

	font-size:1.4rem;
	font-weight:500;

	@media (${props => props.theme.media.tablet}) {
		font-size:1.75rem;
	}
`;

export const Address = styled.div`
	margin:0 0 .25em;
	font-weight:200;
`;

export const AddressLink = styled.a`
		color:inherit;
		text-decoration:none;
		border-bottom: 1px solid transparent;

		&:hover {
		    color: #1b83a4;
		    border-bottom: 1px solid #1b83a4;
	    }
`;

export const Categories = styled.div`
	margin:0;
	color:inherit;
	font-weight:200;
`;

export const Badge = styled.div`
	margin:0 0 .66em;

	&:last-child {
		margin-bottom:0;
	}

	width:100%;
	padding:4px .66em;
	box-sizing:border-box;

	border-radius:.3rem;
	font-size:1.1rem;
	letter-spacing:.015em;
	text-indent:.015em;

	font-weight:500;
	line-height:1em;
	font-variant:all-small-caps;
	text-align:center;

	${({ active }) => {
		if (active) {
			return css`
				color:#9ACD32;
				background:#EFF7E3; /* e99749*/
			`;
		}
		else {
			return css`
				color:#fff;
				background:#ededed;
			`;
		}
	}}

	@media (${props => props.theme.media.tablet}) {
		padding:5px .85em;
		font-size:1.4rem;
	}
`;


/*
 *
 */
export const Links = styled.div`
	display:flex;
	width:100%;
	justify-content:stretch;
	overflow:hidden;
	box-sizing:border-box;
	padding:1rem 1.15rem;

	border-radius:0 0 3px 3px;
	background:#f9f9f9;
	border-top:1px solid #eaeaea;

	@media (${props => props.theme.media.tablet}) {
		padding:1rem;
		width:20em;
		flex-direction:column;
		border-top:0;
		border-left:1px solid #eaeaea;
		border-radius:0 3px 3px 0;
	}
`;

export const Link = styled.a`
	border:0;
	background:none;
	border:1px solid #1b83a4;

	margin:0 .4rem;

	&:first-child {
		margin-left:0;
	}

	&:last-child {
		margin-right:0;
	}

	color:#1b83a4;
	background:#fff;
	text-decoration:none;
	border-radius:.3rem;

	width:100%;
	padding:1rem 1.15rem;
	box-sizing:border-box;
	text-align:center;

	cursor:pointer;

	&:hover {
		background:#1b83a4;
		color:#fff;
	}

	@media (${props => props.theme.media.tablet}) {
		margin:0 0 .66rem;

		height:100%;
		display:flex;
		justify-content:center;
		align-items:center;

		&:last-child {
			margin-bottom:0;
		}
	}

	${({ disabled }) => {
		if (disabled) {
			return css`

				color:#ccc !important;
				background-color:transparent !important;
				border-color:transparent !important;
				cursor:not-allowed;

				&:hover {
					text-decoration:none;
				}
			`;
		}
	}}
`;

export const ShowOnDesktop = styled.span`
	display:none;
	@media (${props => props.theme.media.tablet}) {
		display:inline;
	}
`;

export const ShowOnMobile = styled.span`
	display:inline;
	@media (${props => props.theme.media.tablet}) {
		display:none;
	}
`;


export const Close = styled.button`
	border:0;
	border-radius:.3rem;

	padding:1.5rem 3rem;

	color:#fff;
	font-size:1.4rem;
	text-transform:uppercase;
	background:#777;

	cursor:pointer;

	&:hover {
		background:#555;
	}
`;

export const Section = styled.div`
	margin:0 0 4rem;

	&:last-child {
		margin-bottom:0;
	}
`;

export const H2 = styled.h2`
	margin:0 0 1em;

	text-align:center;
	font-weight:500;
	font-size:1.7rem;
`;

export const Item = styled.div`
	margin:0 0 1.5rem;
	text-align:center;
`;


export const Button = styled.a`
	display:inline-flex;
	
	font-size:1.45rem;
	text-transform:uppercase;
	font-weight:500;
	min-width:18em;
	max-width:100%;
	box-sizing:border-box;

	color:#fff !important;
	background:#1b83a4;
	padding:1.75rem 2.5rem;
	border-radius:.35rem;

	text-decoration:none;

	justify-content: center;
    align-items: center;

	&:hover {
		background:#156A86;
	}
`;

export const DeliverooIcon = styled.span`
    margin-right: 0.5em;
    display: inline-block;
    width: 1.45em;
    height: 1.45em;
    flex: 0 0 auto;
`;
