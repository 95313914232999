/*
 * Dependencies
 */
import styled, { css, createGlobalStyle } from 'styled-components';

//
const TRANSITION_DURATION = '.3s';

/*
 *
 */
export const GlobalStyle = createGlobalStyle`
	body {
		overflow-y:${({ isOpen }) => isOpen ? 'hidden' : 'scroll'} !important;
		padding-right:${({ isOpen, scrollbarWidth }) => isOpen ? `${scrollbarWidth}px` : null};
		pointer-events:none;
	}
`;

/*
 *
 */
export const Content = styled.div`
	height:100%;
	width:100%;
	overflow:hidden;

	opacity:0;
	transform:${({ has_transition }) => has_transition ? 'scale(.75)' : 'none'};
	transform-origin:50% 50%;
	transition:all ${TRANSITION_DURATION} cubic-bezier(0.23,1,0.32,1);
	transition-delay:${({ has_transition }) => has_transition ? TRANSITION_DURATION : 0};

	@media (${props => props.theme.media.tablet}) {
		transform:${({ has_transition }) => has_transition ? 'scale(.95)' : 'none'};
	}
`;

export const ContentInner = styled.div`
	height:100%;
	display:inline;
`;

/*
 * Close
 */
export const Close = styled.div`
	position:absolute;
	top:0;
	left:50%;
	z-index:1;
	margin-left:-3.5rem;

	width:7rem;
	height:7rem;
	padding:2rem;
	box-sizing:border-box;

	display:${({ hide_on_mobile }) => hide_on_mobile ? 'none' : 'block'};

	opacity:0;
	transition:all ${TRANSITION_DURATION} cubic-bezier(0.23,1,0.32,1);
	transition-delay:${({ has_transition }) => has_transition ? TRANSITION_DURATION : 0};

	cursor:pointer;

	@media (${props => props.theme.media.tablet}) {
		top:0;
		right:0;
		left:auto;
		margin-left:0;

		width:6rem;
		height:6rem;
		display:block;

		color:#fff;
	}
`;

/*
 * Root
 */
export const Root = styled.div`
	position:fixed;
	top:0;
	left:0;
	z-index:999;

	width:100%;
	height:100%;
	overflow:hidden;
	pointer-events:auto;
	-webkit-overflow-scrolling:touch;

	background:${({ active, overlay_color }) => active ? (overlay_color === 'light' ? 'rgba(255, 255, 255, 0.95)' : 'rgba(0, 0, 0, 0.8)') : 'rgba(0, 0, 0, 0)'};
	transition:background-color ${TRANSITION_DURATION} cubic-bezier(0.23,1,0.32,1);

	${({ active }) => active ? css`
		& ${Content} {
			opacity:1;
			transform:scale(1);
		}

		& ${Close} {
			opacity:1;
		}
	` : null};

	${({ overlay_color }) => overlay_color === 'light' ? css`
		& ${Close} {
			color:#000;
		}
	` : null}
`;
