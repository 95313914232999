/*
 * Dependencies
 */

// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
//import Icon from 'components/icon';
import Modal from 'components/modal';
import DialogFooter from './footer/index.js';
import DialogContent from './content/index.js';
import DialogContext, { Context } from './context/index.js';

// Styles
import {
	Container,
	Header, Title, TitleText, TitleDesc,
	Content,
	CloseInOverlay
} from './styles.js';

/*
 * DIALOG
 * =====
 */
class Dialog extends React.PureComponent {

	static propTypes = {
		children: PropTypes.node,
		isOpen: PropTypes.bool.isRequired,
		title: PropTypes.oneOfType([
			PropTypes.node.isRequired,
			PropTypes.shape({
				text: PropTypes.node.isRequired,
				desc: PropTypes.node.isRequired,
				color: PropTypes.string
			}).isRequired
		]),
		addon: PropTypes.node,
		headerContent: PropTypes.node,
		onRequestClose: PropTypes.func
	};

	static defaultProps = {
		isOpen: false
	};

	static contextType = Context;
	static Footer = DialogFooter;
	static Content = DialogContent;

	constructor (props) {
		super(props);

		this.state = {
			contentRef: null
		};

		this.registerRef = this.registerRef.bind(this);
		this.CloseButton = this.CloseButton.bind(this);
	}

	/*
	 * Save callback ref to state for trigger a clean render
	 * --
	 * https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
	 */
	registerRef (el) {
		this.setState({
			contentRef: el
		});
	}

	/*
	 *
	 */
	CloseButton (props) {
		return (
			this.props.onRequestClose ? (
				<CloseInOverlay scrollbarWidth={props.scrollbarWidth} onClick={this.props.onRequestClose}>
					×
				</CloseInOverlay>
			) : null
		);
	}

	/*
	 *
	 */
	render () {

		const { title, children, isOpen, onRequestClose } = this.props;

		return isOpen ? (
			<Modal
				isOpen={isOpen}
				onRequestClose={onRequestClose}
				CloseButton={this.CloseButton}
				scrollable={this.state.contentRef}
			>
				<DialogContext>
					<Context.Consumer>
						{({ content, footer }) => {
							return (
								<Container>
									{/* Will not be rendered, always empty */}
									{children}

									{title && (
										<Header>
											{title.text ? (
												<Title>
													<TitleText>{title.text}</TitleText>
													{title.desc && <TitleDesc>{title.desc}</TitleDesc>}
												</Title>
											) : (
												<Title>
													<TitleText>{title}</TitleText>
												</Title>
											)}
										</Header>
									)}

									<Content
										ref={this.registerRef}
										has_header={!!title}
										has_footer={!!footer}
									>
										{content}
									</Content>

									{footer && footer}
								</Container>
							);
						}}
					</Context.Consumer>
				</DialogContext>
			</Modal>
		) : null;
	}
}

export default Dialog;
