/*
 *
 */
import React from 'react';
import styled, { css } from 'styled-components';

/*
 *
 */
const Root = styled.div`
	margin:0 0 1.25rem;
	display: flex;
	border-radius:.4rem;
	padding: 1.5rem 1.75rem;
	font-size:1.4rem;
	line-height: 2rem;
	background: #fff;
    box-shadow: 0 0 2rem 0 rgba(0,0,0,.05);
    &:last-child {
    	margin-bottom: 4rem;
    }
	${({ type }) => {
		switch (type) {
			case 'success':
				return css`
					border-left: 3px #5FA052 solid;
				`;
			case 'info':
			default:
				return css`
					border-left: 3px #1B83A4 solid;
				`;
			case 'promo-deliveroo':
				return css`
					border-left: 3px #01CCB8 solid;
				`;
			case 'warning':
				return css`
					border-left: 3px #FFCC00 solid;
				`;
		}
	}}
	& p {
		margin:0 0 .75em;
		&:last-child {
			margin-bottom:0;
		}
	}
	& a {
		color:inherit;
	}
`;

const Emoji = styled.div`
	box-sizing: content-box;
	margin:auto;
	margin-right: 1.25rem;
`;

const Message = styled.div`
	width:100%;
	padding-left : 1.25rem;
	border-left: 1px solid lightgray;
`;

/*
 *
 */
export default function Alert ({ children, emoji, type='info' }) {

	return (
		<Root type={type}>
			<Emoji>{emoji}</Emoji>
			<Message>{children}</Message>
		</Root>
	);
}