/*
 *
 */
import React from 'react';
import styled from 'styled-components';

const Dotdotdot = styled.div`
	display:block;
	max-width:100%;
	white-space:nowrap;
	overflow:hidden;
	text-overflow:ellipsis;
`;

/*
 *
 */
export function Ellipsis ({ children }) {

	return (
		<Dotdotdot>
			{children}
		</Dotdotdot>
	);
}

export default Ellipsis;
